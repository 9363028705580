<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-response-warning v-if="! state.hasAccess" :error="state.accessError" />

    <div v-else >
      <div class="flex flex-row justify-between items-center border-b pb-2">
        <h2 class="font-bold text-lg sm:text-2xl">{{ state.machineName }}</h2>

        <router-link :to="{ name: 'SettingsMachinesIndex'}" class="group">
          <div class=" flex items-center">
            <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <p class="text-sm text-gray-500 group-hover:text-gray-900">Back</p>
          </div>
        </router-link>
      </div>

      <div class="space-y-6 xl:space-y-8 mt-6 xl:mt-8">
        <form-layout
          title="Machine Maintenance"
          icon="restart_alt"
        >
          <errors :errors="state.rebootErrors" class="mb-4" />

          <button
            type="button"
            @click="reboot"
            :disabled="state.isRebooting"
            :class="{ 'cursor-not-allowed': state.isRebooting }"
          >
            <add-button-inner :title="state.isRebooting ? 'Rebooting ...' : 'Reboot Modem'" />
          </button>

          <button
            class="ml-4"
            type="button"
            @click="resetRegisterState"
            :disabled="state.isResetting"
            :class="{ 'cursor-not-allowed': state.isResetting }"
          >
            <add-button-inner :title="state.isResetting ? 'Resetting ...' : 'Reset Register State'" />
          </button>

          <button
            class="ml-4"
            type="button"
            @click="fetchLogs"
            :disabled="state.isLogPolling"
            :class="{ 'cursor-not-allowed': state.isLogPolling }"
          >
            <add-button-inner
              :class="{ 'bg-blumine': state.isLogPolling }"
              :title="state.isLogPolling ? 'Please wait, retrieving logs...' : 'Retrieve Device Logs'"
            />
          </button>
        </form-layout>

        <form-layout
          @submit="submit"
          title="Admin Command"
          icon="info"
          :is-loading="state.isLoading"
        >
          <errors :errors="state.errors" class="mb-4" />
          {{ state.activeMachineId }}
          <div v-for="(item, index) in state.form" :key="index" class="flex items-center space-x-4 mb-2">
            <text-input label="Register" v-model="item.register_to_set" required />
            <text-input label="Value" v-model="item.value" required />
            <button type="button" @click="removeItem(index)" v-if="state.form.length > 1" class="group text-blumine flex items-center space-x-1 focus:outline-none mt-4">
              <span class="text-base material-icons-outlined">remove_circle_outline</span>
              <span class="group-hover:underline text-sm">Remove</span>
            </button>
          </div>
          <button type="button" @click="addItem" class="group text-blumine flex items-center space-x-1 focus:outline-none">
            <span class="text-base material-icons-outlined">add_circle_outline</span>
            <span class="group-hover:underline text-sm">Add</span>
          </button>

          <template #actions>
            <v-button type="submit" :is-loading="state.isSaving">Send Command</v-button>
          </template>
        </form-layout>

        <form-layout
          title="Registers"
          icon="info"
          :is-loading="state.isLoading"
        >
          <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 xxl:grid-cols-6 gap-2">
            <div v-for="(value, register) in state.registers" :key="register">
              <div class="flex flex-row items-center flex-wrap justify-between text-sm border py-1 px-2 rounded hover:bg-big-stone hover:text-white transition duration-100 ease-out">
                <span>{{ register }}:</span>
                <span class="font-bold" :title="value">{{ parseInt(value) }}</span>
              </div>
            </div>
          </div>
        </form-layout>
      </div>

    </div>
  </layout>
</template>

<script>
import { ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import date from '@/helpers/date.js'
import Layout from '@/layouts/Default.vue'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import AddButtonInner from '@/components/buttons/AddButtonInner.vue'
import TextInput from '@/components/form/Text.vue'
import VButton from '@/components/form/Button.vue'
import Errors from '@/components/form/Errors.vue'
import FormLayout from '@/components/form/Layout.vue'
import { onBeforeUnmount } from '@vue/runtime-core'
import SettingsNav from '@/components/settings/Nav'

export default {
  name: 'settings-machines-edit',

  components: {
    Layout,
    AlertResponseWarning,
    TextInput,
    VButton,
    Errors,
    FormLayout,
    SettingsNav,
    AddButtonInner,
  },

  setup() {
    const registerTimeoutHandler = ref(null)
    const logPollingTimeoutHandler = ref(null)

    const route = useRoute()
    const machineId = route.params.machineId
    const state = reactive({
      isRebooting: false,
      isResetting: false,
      isLogPolling: false,
      rebootErrors: [],
      form: [{}],
      errors: [],
      registers: [],
      machineName: null,
      isSaving: false,
      isLoading: false,
      hasAccess: true,
      accessError: null,
    })

    const reboot = async () => {
      state.rebootErrors = []
      state.isRebooting = true

      try {
        await http.post(`/machine/${machineId}/reboot`)
        notification.success('Success', 'Machine Rebooted')
      } catch (e) {
        if (e.response.data.Message) {
          state.rebootErrors = [e.response.data.Message]
        } else {
          state.rebootErrors = ['Something went wrong. Please, try again.']
        }
      }

      state.isRebooting = false
    }

    const resetRegisterState = async () => {
      const warning = 'This will reset all existing machine state, which will cause all existing registers to get re-processed by register change processor. Machine must be rebooted after reset for the changes to apply.'

      if (!confirm(warning)) return

      state.rebootErrors = []
      state.isResetting = true
      try {
        await http.post(`/machine/${machineId}/admincommand/reset`)
        notification.success('Success', 'Machine Register State was reset')
        getRegisters()
      } catch (e) {
        if (e.response.data.Message) {
          state.rebootErrors = [e.response.data.Message]
        } else {
          state.rebootErrors = ['Something went wrong. Please, try again.']
        }
      }
      state.isResetting = false
    }

    const downloadLog = (filename, logs_url) => {
      window.location = logs_url;
    }

    const fetchLogs = async () => {
      state.rebootErrors = []
      state.isLogPolling = true
      try {
        const { data, status } = await http.get(`/machine/${machineId}/logs`)
        if (status === 204 || data.new_logs_requested === true) {
          logPollingTimeoutHandler.value = setTimeout(() => {
            fetchLogs()
          }, 10000)
        }
        else if (data.new_logs_requested === false) {
          const last_modified = date.instance(data.last_modified).format('YYYY-MM-DD_HH_mm_ss')
          const filename = `device_logs_${machineId}_${last_modified}.txt`
          downloadLog(
            filename,
            data.logs_url
          )
          state.isLogPolling = false
          notification.success('Success', `Log download started`, false)
        }
      } catch (e) {
        console.log(e)
        if (e.response.data.Message) {
          state.rebootErrors = [e.response.data.Message]
        } else {
          state.rebootErrors = ['Something went wrong. Please, try again.']
        }
        state.isLogPolling = false
      }
    }

    const addItem = () => {
      state.form.push({})
    }

    const removeItem = (index) => {
      if (state.form.length < 2) {
        return
      }

      state.form.splice(index, 1)
    }

    const submit = async () => {
      state.errors = []
      state.isSaving = true

      try {
        const { data } = await http.post(`/machine/${machineId}/admincommand`, {
          register_values: state.form
        })
        state.registers = data
        state.form = [{}]
        notification.success('Success', 'Commands has been send')
        close()
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
        } else {
          state.errors.push('Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    const getRegisters = async (showLoading = true) => {
      if (showLoading) {
        state.isLoading = true
      }

      const { data } = await http.get(`/machine/${machineId}/admincommand`)

      state.registers = data
      state.isLoading = false

      clearRegisterTimeoutHandler()
      registerTimeoutHandler.value = setTimeout(async () => await getRegisters(false), 10000)
    }

    const getMachine = async () => {
      try {
        const { data } = await http.get(`/machine/${machineId}`)

        state.machineName = data.model.name
      }
      catch (e) {
        state.hasAccess = false
        state.accessError = e
      }
    }

    const clearRegisterTimeoutHandler = () => {
      clearTimeout(registerTimeoutHandler.value)
      registerTimeoutHandler.value = null
    }

    const clearLogPollingTimeoutHandler = () => {
      clearTimeout(logPollingTimeoutHandler.value)
      logPollingTimeoutHandler.value = null
    }

    onBeforeUnmount(() => {
      clearRegisterTimeoutHandler()
      clearLogPollingTimeoutHandler()
    })

    getMachine()
    getRegisters()

    return {
      state,
      machineId,
      addItem,
      removeItem,
      submit,
      reboot,
      resetRegisterState,
      fetchLogs,
    }
  }
}
</script>
